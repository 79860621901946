import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import useTabAnimation from './useTabAnimation';
import './Tabs.scss';

function Tabs({
  selector,
  data,
  onChange,
  dropDown,
  dropDownFontSize,
  timerAutoPlay,
  varient,
  customClass,
}) {
  const [activeTab, setActiveTab] = useState(data.length ? data[0]?.key : '');
  const [hovered, setHovered] = useState(false);
  const tabRef = useRef();
  const activeClass = (key) => `${(activeTab === key && 'active') || ''}`;
  const cycleNext = () => {
    const i =
      ((data || []).findIndex((d) => d?.key === activeTab) + 1) % data?.length;
    if (i >= 0 && data.length > i) {
      const { key } = data[i];

      setActiveTab(key);
    }
  };
  const { setActiveTabIndex } = useTabAnimation(
    timerAutoPlay,
    tabRef,
    hovered,
    cycleNext,
  );
  const onClick = (key) => {
    setActiveTab(key);
  };
  const onKeyDown = (key) => (e) => {
    /** Enter button */
    if (e.keyCode === 13) {
      onClick(key);
    }
  };
  const onMouseEnter = () => {
    if (timerAutoPlay) setHovered(true);
  };
  const onMouseLeave = () => {
    if (timerAutoPlay) setHovered(false);
  };
  useEffect(() => {
    if (onChange) onChange(activeTab);
    if (timerAutoPlay)
      setActiveTabIndex((data || []).findIndex((d) => d?.key === activeTab));
  }, [activeTab]);
  return (
    <div
      className={`tabs-container tab-${varient}  ${selector} ${
        (dropDown && 'drop-down') || ''
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <ul className="tabs-heading scroll-snap-x">
        {data.map((d) => (
          <li key={d.key} className={activeClass(d.key)}>
            <button type="button" onClick={() => onClick(d.key)}>
              {d.heading}
            </button>
          </li>
        ))}
      </ul>
      {/* <div className="tabs-underline" /> */}
      <div className="tab-contents">
        {data.map(({ key, content, shouldUnmount }) => (
          <div className={`content-container ${activeClass(key)}`} key={key}>
            {shouldUnmount && activeTab !== key ? null : content}
          </div>
        ))}
      </div>
      {dropDown && (
        <div className="dropdown-tabs" ref={tabRef}>
          {data.map((d) => (
            <div
              key={d.key}
              role="button"
              className={`button-unstyled tab text-left overflow-hidden w-full ${
                activeTab === d.key && timerAutoPlay
                  ? 'border-timer relative'
                  : ''
              }`}
              onClick={() => onClick(d.key)}
              onKeyDown={onKeyDown(d.key)}
              tabIndex={0}>
              <div className="progress-line">
                <div className="progress" />
              </div>
              <div
                className={`flex flex-row justify-between  font-semibold font-body py-[20px] ${customClass} ${dropDownFontSize} ${
                  (activeTab === d.key &&
                    `active-tab ${
                      varient === 'light' ? 'text-white' : 'text-cf-primary'
                    }`) ||
                  (varient === 'light' ? 'text-white' : 'text-cf-hero')
                }`}>
                {d.dropDownHeading || d.heading}
                <Icon
                  // name="chevron"
                  name={
                    varient === 'dark' || varient === 'grey'
                      ? 'chevron'
                      : 'chevron-white'
                  }
                  width="11px"
                  className="self-center"
                  rotate={activeTab === d.key ? '180deg' : '0deg'}
                />
              </div>
              <div className={`tab-content ${activeClass(d.key)}`}>
                {d.shouldUnmount && activeTab !== d.key ? null : d.content}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
Tabs.defaultProps = {
  selector: '',
  data: [],
  onChange: () => {},
  dropDown: false,
  dropDownFontSize: 'text-2.5sm',
  varient: 'dark',
  timerAutoPlay: false,
  customClass: '',
};
Tabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      dropDownHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      content: PropTypes.node,
      /** if true will unmount if not active tab in both mobile and desktop view , default behaviour is false */
      shouldUnmount: PropTypes.bool,
    }),
  ),
  dropDown: PropTypes.bool,
  dropDownFontSize: PropTypes.string,
  selector: PropTypes.string,
  timerAutoPlay: PropTypes.bool,
  onChange: PropTypes.func,
  varient: PropTypes.oneOf(['dark', 'light', 'grey']),
  customClass: PropTypes.string,
};
export default Tabs;
