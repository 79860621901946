import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

function CheckList({ data, itemMargin, className, fontClass }) {
  return (
    <ul className={`p-0 list-none max-w-full ${className}`}>
      {data.map(({ id, text }) => (
        <li className={`${itemMargin} last:mb-0 flex`} key={id}>
          <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
            <Icon name="tick" width="9.21px" height="6.29px" fill="#fff" />
          </span>
          <h3 className={`font-body !m-0 ${fontClass}`}>{text}</h3>
        </li>
      ))}
    </ul>
  );
}
CheckList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
  itemMargin: PropTypes.string,
  fontClass: PropTypes.string,
  className: PropTypes.string,
  //   type: PropTypes.oneOf(['purple', 'white']),
};
CheckList.defaultProps = {
  data: [],
  itemMargin: 'mb-4',
  fontClass: 'text-2.5sm md:text-[14px]  md:leading-6',
  className: 'mb-[32px]',
};
export default CheckList;
