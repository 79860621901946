import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { faq } from '../../utils/contentProvider';

import './FAQ.scss';

const ICON_MAP = {
  merchant: (active) => (
    <svg
      className="inline-block mr-2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6673 17.5V15.8333C16.6673 14.9493 16.3161 14.1014 15.691 13.4763C15.0659 12.8512 14.218 12.5 13.334 12.5H6.66732C5.78326 12.5 4.93542 12.8512 4.31029 13.4763C3.68517 14.1014 3.33398 14.9493 3.33398 15.8333V17.5"
        stroke={!active ? '#8B7FA3' : '#6933D3'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99935 9.16667C11.8403 9.16667 13.3327 7.67428 13.3327 5.83333C13.3327 3.99238 11.8403 2.5 9.99935 2.5C8.1584 2.5 6.66602 3.99238 6.66602 5.83333C6.66602 7.67428 8.1584 9.16667 9.99935 9.16667Z"
        stroke={!active ? '#8B7FA3' : '#6933D3'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  customer: (active) => (
    <svg
      className="inline-block mr-2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.666 5.8335H3.33268C2.41221 5.8335 1.66602 6.57969 1.66602 7.50016V15.8335C1.66602 16.754 2.41221 17.5002 3.33268 17.5002H16.666C17.5865 17.5002 18.3327 16.754 18.3327 15.8335V7.50016C18.3327 6.57969 17.5865 5.8335 16.666 5.8335Z"
        stroke={!active ? '#8B7FA3' : '#6933D3'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="transition-all"
      />
      <path
        d="M13.3327 17.5V4.16667C13.3327 3.72464 13.1571 3.30072 12.8445 2.98816C12.532 2.67559 12.108 2.5 11.666 2.5H8.33268C7.89065 2.5 7.46673 2.67559 7.15417 2.98816C6.84161 3.30072 6.66602 3.72464 6.66602 4.16667V17.5"
        stroke={!active ? '#8B7FA3' : '#6933D3'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="transition-all"
      />
    </svg>
  ),
  at: (active) => (
    <svg
      className="inline-block mr-2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0013 13.3337C11.8423 13.3337 13.3346 11.8413 13.3346 10.0003C13.3346 8.15938 11.8423 6.66699 10.0013 6.66699C8.16035 6.66699 6.66797 8.15938 6.66797 10.0003C6.66797 11.8413 8.16035 13.3337 10.0013 13.3337Z"
        stroke={!active ? '#8B7FA3' : '#6933D3'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="transition-all"
      />
      <path
        d="M13.3346 6.66648V10.8331C13.3346 11.4962 13.598 12.1321 14.0669 12.6009C14.5357 13.0698 15.1716 13.3331 15.8346 13.3331C16.4977 13.3331 17.1336 13.0698 17.6024 12.6009C18.0713 12.1321 18.3346 11.4962 18.3346 10.8331V9.99981C18.3345 8.119 17.6982 6.29354 16.529 4.82025C15.3599 3.34696 13.7268 2.3125 11.8952 1.88506C10.0636 1.45762 8.14125 1.66234 6.44077 2.46595C4.74028 3.26955 3.36164 4.62477 2.52902 6.31124C1.69641 7.99772 1.45878 9.91626 1.85478 11.7549C2.25078 13.5936 3.25712 15.2442 4.71017 16.4384C6.16321 17.6326 7.9775 18.3001 9.85803 18.3324C11.7386 18.3648 13.5747 17.76 15.068 16.6165"
        stroke={!active ? '#8B7FA3' : '#6933D3'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="transition-all"
      />
    </svg>
  ),
};

/**
 * Function which return icon based on active state.
 * @param {string} type
 * @param {boolean} active
 *
 */
const getIcon = (type, active) =>
  ICON_MAP[type] ? ICON_MAP[type](active) : ICON_MAP.merchant(active);

const FAQ = ({
  isPayouts,
  style,
  data,
  customerData,
  getActive,
  arrow,
  alwaysOpen,
  supportLink,
  supportLinkHref,
  borderLast,
  open,
  faqTabs,
}) => {
  const [faqActive, setFaqActive] = useState(
    isPayouts || open ? Number(open) : -1,
  );

  const toggleFaqAccordion = useCallback(
    (id) => () => {
      if (alwaysOpen) {
        setFaqActive(id);
      } else {
        setFaqActive((state) => (state === id ? -1 : id));
      }
    },
    [alwaysOpen],
  );

  useEffect(() => {
    if (getActive) {
      getActive(faqActive);
    }
  }, [getActive, faqActive]);

  const [customerDataType, setCustomerDataType] = useState(false);

  const toggleDataType = (bool) => () =>
    setCustomerDataType((state) => (state === bool ? state : !state));

  return (
    <section className="faq" style={{ ...style }}>
      <div className="row row-no-margin align-flex-start justify-space-between">
        {!isPayouts && (
          <div className="faq-heading">
            <div className="font-heading font-semibold text-lg md:text-xl faq-div">
              Frequently Asked Questions
            </div>
            {customerData ? (
              <div className="flex md:block mt-10 border-b md:border-b-0 md:border-l border-cf-stroke mb-[16px] md:mb-0 overflow-x-auto">
                {faqTabs.map(({ id, heading, content, icon }) => (
                  <button
                    key={id}
                    type="button"
                    onClick={toggleDataType(!!id)}
                    className={`pb-4 md:pb-0 md:pl-5 relative before:h-[3px] md:before:h-auto before:w-full md:before:w-[3px] before:absolute before:right-[-2px] md:right-auto before:left-[-2px] md:before:top-[-4px] before:bottom-0 before:transition-all
                    ${
                      !!id !== !!customerDataType
                        ? ''
                        : 'before:bg-cf-primary active'
                    }
                    ${id ? '' : 'md:mb-6 mr-5 md:mr-0'}`}>
                    <h5 className="text-2md font-semibold font-body text-left flex">
                      {getIcon(icon, !!id === !!customerDataType)}
                      <span
                        className={`
                        whitespace-nowrap
                        ${!!id !== !!customerDataType ? 'opacity-50' : ''}`}>
                        {heading}
                      </span>
                    </h5>
                    <p
                      className={`${
                        !!id !== !!customerDataType ? 'opacity-50' : ''
                      } pl-7 text-2sm hidden md:block`}>
                      {content}
                    </p>
                  </button>
                ))}
              </div>
            ) : (
              ''
            )}
            <div className="hidden md:block faq-support-section-top">
              <h4 className="body-font">Have more questions?</h4>
              <a
                href={supportLinkHref}
                className="link text-cf-green hover:text-cf-green">
                Visit Our Support Page
                <svg
                  className="ml-2.5"
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L5 5.5L1 10"
                    stroke="#05C16E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        )}
        <div className={`faq-content ${borderLast ? 'border-last' : ''}`}>
          {(customerDataType ? customerData : data || faq).map(
            ({ id, q, a }) => (
              <div
                key={id}
                className={`accordion${faqActive === id ? ' is-active' : ''}`}>
                <button
                  type="button"
                  className="button-unstyled accordion-head"
                  onClick={toggleFaqAccordion(id)}>
                  <div className="faq-question">{q}</div>
                  {arrow === 'plus' ? (
                    <>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          className={`transition-all duration-300 origin-center ${
                            faqActive === id ? 'rotate-90' : ''
                          }`}
                          d="M6 1.35714V10.6429"
                          stroke="#5C428C"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 6H11"
                          stroke="#5C428C"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          className={`transition-all duration-300 origin-center ${
                            faqActive === id ? '' : '-scale-y-100'
                          }`}
                          d="M11 6.5L6 1.5L1 6.5"
                          stroke="#5C428C"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </>
                  )}
                </button>
                <div className="accordion-body">{a}</div>
              </div>
            ),
          )}
        </div>
        {supportLink && (
          <div className="md:hidden faq-support-section-bottom">
            <h4 className="body-font">Have more questions?</h4>
            <a
              href={supportLinkHref}
              className="link text-cf-green hover:text-cf-green">
              Visit Our Support Page
              <svg
                className="ml-2.5"
                width="6"
                height="11"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1L5 5.5L1 10"
                  stroke="#05C16E"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

FAQ.defaultProps = {
  isPayouts: false,
  style: null,
  data: null,
  getActive: null,
  arrow: 'plus',
  customerData: null,
  alwaysOpen: false,
  supportLink: true,
  supportLinkHref: 'https://www.cashfree.com/help/hc',
  borderLast: false,
  open: 0,
  faqTabs: [
    {
      id: 0,
      heading: 'For Merchants',
      content: 'Using Cashfree Payments for my businesss',
      icon: 'merchant',
    },
    {
      id: 1,
      heading: 'For Customer',
      content: 'I recently paid online via Cashfree Payments',
      icon: 'customer',
    },
  ],
};

FAQ.propTypes = {
  isPayouts: PropTypes.bool,
  style: PropTypes.shape({
    border: PropTypes.string,
    margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      q: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      a: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  ),
  getActive: PropTypes.func,
  arrow: PropTypes.string,
  customerData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      q: PropTypes.string,
      a: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  ),
  alwaysOpen: PropTypes.bool,
  supportLink: PropTypes.bool,
  supportLinkHref: PropTypes.string,
  borderLast: PropTypes.bool,
  open: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  faqTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      icon: PropTypes.string.isRequired,
    }),
  ),
};

export default FAQ;
